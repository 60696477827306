@font-face {
        font-family: "roboto-100";
        src: url("./assets/fonts/roboto-100.ttf") format("truetype");
      }
@font-face {
        font-family: "roboto-700";
        src: url("./assets/fonts/roboto-700.ttf") format("truetype");
      }
@font-face {
        font-family: "roboto-500";
        src: url("./assets/fonts/roboto-500.ttf") format("truetype");
      }
@font-face {
        font-family: "roboto-regular";
        src: url("./assets/fonts/roboto-regular.ttf") format("truetype");
      }
@font-face {
        font-family: "roboto-300";
        src: url("./assets/fonts/roboto-300.ttf") format("truetype");
      }
@font-face {
        font-family: "roboto-900";
        src: url("./assets/fonts/roboto-900.ttf") format("truetype");
      }

body {
 overflow-x: hidden; 
}


::-webkit-scrollbar-button {
background-image:url('');
background-repeat:no-repeat;
width:6px;
height:0px
}

::-webkit-scrollbar-track {
background-color:#32312e01;
margin-top: 20px;
margin-bottom: 20px;
/* box-shadow:0px 0px 3px #000 inset; */
}

::-webkit-scrollbar-thumb {
-webkit-border-radius: 5px;
border-radius: 5px;
background-color:#ffcb17;
/* box-shadow:0px 1px 1px #fff inset; */
background-image:url('https://yraaa.ru/_pu/24/59610063.png');
background-position:center;
background-repeat:no-repeat;
}

::-webkit-resizer{
background-image:url('');
background-repeat:no-repeat;
width:7px;
height:0px
}

::-webkit-scrollbar{
width: 11px;
}



::-webkit-input-placeholder {color:#fffff0;}
input {outline:none;}

.tinkoffPayRow{display:block;margin:1%;width:160px;}

.BackImage{
  position: fixed;
  z-index: -1;
  width: 100vw;
}

.redSmudge {
  width: 160px;
  height: 160px;
  background-color: rgba(136,11,11,0.55);
  /* opacity: 0.55; */
  margin: 20px;
  border-radius: 100px;
  transition: 1s;
  padding: 20px;
  
  position: relative;
}

.redSmudgeText{
  position: absolute;
  transition: 1s;
  /* background-color: rgba(136,11,11,0.55); */
  transition: 1s;
  padding: 20px;
  overflow: hidden;
}

.redSmudgeTextP{
  color: #fff;
}

.redSmudgeTextSVG{
  opacity: 1;
  transition: 1s;
}



.landingTitle {
  height: 100px;
  padding-top: 60px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  display: flex;
}
.landingTitleText {
  font-family: roboto-900;
  color: #c5c5c5;
  font-size: 40px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 2px;
}
.LogInBlackOut {
  position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 255;
            transition: 1s;
            overflow: hidden;
}
.headerContainer {
  overflow: hidden;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: row;
  border-color: #000000;
  border-width: 0px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  background-color: rgba(19,16,16,0.59);
  border-style: solid; 
  width: 100vw;
          height: 60px;
          transition: 2s;
}

.HeadButton {
  /* width: 100px; */
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  margin-left: 8px;
  padding: 14px;
  border: none;
  cursor: pointer
}

.ButtonSupport {
display: flex;
  width: 241px;
  height: 40px;
  background-color: #d7d515;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 8px;
  padding-left: 8px;
  border-radius: 40px;
  margin-right: 20px;
  border: none;
  box-shadow: 5px 5px 5px  0.44px rgba(0,0,0,1) ;
  cursor: pointer
}

.ButtonSupportText {
  font-family: roboto-500;
  height: auto;
  width: auto;
  color: rgba(53,14,14,1);
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  cursor: pointer
}

.menuFooterButton{
  display: flex;
  width: 100px;
  height: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  margin-left: 8px;
  padding: 4px;
  letter-spacing: 2px;
  border: none;
cursor: pointer;
}

.menuFooterButtonText{
  font-family: roboto-300;
  height: 20px;
  flex: 1 1 0%;
  color: rgba(228,212,212,1);
  align-self: stretch;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  transition: 1s;
}

.inputDiv{
  transition: 2s;
overflow: visible;
  height: 40px;
  align-self: center;
  background-color: rgba(0, 0, 20, 0.5);
  background-color: #e6e6e680;
  border-radius: 20px;
  flex-direction: row;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  justify-content: space-between;
  display: flex;
  color: #fffd;
  color: #000
}

.ButtonAuth{
  overflow: hidden;
display: flex;
transition: 2s;
  height: 40px;
  background-color: rgba(215,213,21,1);
  flex-direction: row;
  border-radius: 40px;
  width: 40px;
  margin: 0px;
  padding: 0px;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: 5px 5px 5px  0.44px rgba(0,0,0,1) ;
  cursor: pointer;
}

.selectionCityCont{
   max-height: 0px;
   align-self: stretch;
   height: auto;
   /* align-self: center; */
  background-color: rgba(25,27,45,0.9);;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
  z-index: 5;
   transition: 1s;
}

.selectionCityDiv{
  background-color:rgba(0, 0, 8, 0.959);
  margin-bottom: 3px;
  transition: 0.8s;
}

.selectionCityDiv:hover{
  background-color:#a58510f5;
  cursor: pointer;
  height: 40px;
  transition: 0.8s;
  
}

.selectionCityText{
  color: #bbb;
  margin-left: 10px;                        
}

.selectionCityDiv:hover .selectionCityText{
  color: #000;                      
}

.clearRegionTextButton{
  cursor: pointer;
  transition: 1s;
}

.smallVideo{
  width: 160px;
  height: 90px;
  background-color: rgba(4,4,4,1);
  border-radius: 8px;
  margin-right: 5px;
}

.bigVideo{
  width: 320px;
  height: 180px;
  background-color: rgba(4,4,4,1);
  border-radius: 8px;
  margin-right: 5px;
}

.inputConteiner150{
  width: fit-content;
  height: 33px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(4,4,4,1);
  border-radius: 8px;
  margin: 2px;
  display: flex;
}

.tegConteiner{
  width: fit-content;
  align-self: flex-start;
  height: auto;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffcb17;
  border-radius: 8px;
  margin: 2px;
  display: flex;
}

.widgetTitleText{
  font-family: roboto-700;
  height: auto;
  color: #e4d4d4;
  font-size: 19px;
  margin: 12px;
  margin-left: 20px;
}

@media (max-width: 1300px) {
    @media (min-height: 900px) {
    .BackImage{
      width: auto;
      height: 100vh;
      background-position-x: right;
      left: -190px;
    }
  }
}

@media (max-width: 450px) {
  .BackImage{
    width: auto;
    height: 100vh;
    background-position-x: right;
    left: -580px;
  }
  .bigVideo{
    width: 240px;
    height: 135px;
  }
  @media (min-height: 800px) {
    .BackImage{
      width: auto;
      height: 100vh;
      background-position-x: right;
      left: -700px;
    }
  }
  .headerContainer {
    right: 0px;
    flex-direction: column;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    justify-content: center;
    flex-wrap: nowrap;
    /* align-items: flex-end; */
    background-color: rgba(1, 1, 20, 0.8);
    border-style: solid; 
    width: 170px;
    height: 100vh;
  }
  .landingTitleText{
    font-size: 22px;
    padding: 20px;
  }
  .ButtonSupport {
    width: 100px;
    height: 60px;
    margin-top: 20px;
    padding-left: 20px;
  }
  .ButtonSupportText {
    font-size: 20px;
  }
}





/* кнопка меню для мобилы */

.burgerButton {
  position: absolute;
  top: 50px;
  right: 0px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 200;
  display: none;
}

@media (max-width: 450px) {
  .burgerButton {
    display: block;
  }
}

.centerized {
  width: 50px;
  height: 32px;
  cursor: pointer;
  z-index: 50;
}

.centerized .bar1,
.centerized .bar2,
.centerized .bar3 {
  height: 5px;
  width: 100%;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  -webkit-transition: background-color .2s ease-in-out;
  transition: background-color .2s ease-in-out;
}

.centerized .bar1 {
  -webkit-animation: animate-line-1-rev .7s ease-in-out;
  animation: animate-line-1-rev .7s ease-in-out;
}

.centerized .bar2 {
  margin: 10px 0;
  -webkit-animation: animate-line-2-rev .7s ease-in-out;
  animation: animate-line-2-rev .7s ease-in-out;
}

.centerized .bar3 {
  -webkit-animation: animate-line-3-rev .7s ease-in-out;
  animation: animate-line-3-rev .7s ease-in-out;
}

.centerized:hover .bar1,
.centerized:hover .bar2,
.centerized:hover .bar3 {
  background-color: #fff;
}

.centerized:hover .bar1,
.centerized:hover .bar2,
.centerized:hover .bar3 {
  background-color: #fff;
}

.centerized[data-open='true'] .bar1 {
  -webkit-animation: animate-line-1 0.7s cubic-bezier(0.3, 1, 0.7, 1) forwards;
  animation: animate-line-1 0.7s cubic-bezier(0.3, 1, 0.7, 1) forwards;
}

.centerized[data-open='true']  .bar2 {
  -webkit-animation: animate-line-2 0.7s cubic-bezier(0.3, 1, 0.7, 1) forwards;
  animation: animate-line-2 0.7s cubic-bezier(0.3, 1, 0.7, 1) forwards;
}

.centerized[data-open='true']  .bar3 {
  -webkit-animation: animate-line-3 0.7s cubic-bezier(0.3, 1, 0.7, 1) forwards;
  animation: animate-line-3 0.7s cubic-bezier(0.3, 1, 0.7, 1) forwards;
}

.no-animation {
  -webkit-animation: none !important;
  animation: none !important;
}

@-webkit-keyframes animate-line-1 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(0deg);
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
  50% {
    -webkit-transform: translate3d(0, 14px, 0) rotate(0);
    transform: translate3d(0, 14px, 0) rotate(0);
  }
  100% {
    -webkit-transform: translate3d(0, 14px, 0) rotate(45deg);
    transform: translate3d(0, 14px, 0) rotate(45deg);
  }
}

@keyframes animate-line-1 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(0deg);
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
  50% {
    -webkit-transform: translate3d(0, 14px, 0) rotate(0);
    transform: translate3d(0, 14px, 0) rotate(0);
  }
  100% {
    -webkit-transform: translate3d(0, 14px, 0) rotate(45deg);
    transform: translate3d(0, 14px, 0) rotate(45deg);
  }
}

@-webkit-keyframes animate-line-2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes animate-line-2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
}

@-webkit-keyframes animate-line-3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(0deg);
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
  50% {
    -webkit-transform: translate3d(0, -22px, 0) rotate(0);
    transform: translate3d(0, -22px, 0) rotate(0);
  }
  100% {
    -webkit-transform: translate3d(0, -22px, 0) rotate(135deg);
    transform: translate3d(0, -22px, 0) rotate(135deg);
  }
}

@keyframes animate-line-3 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) rotate(0deg);
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
  50% {
    -webkit-transform: translate3d(0, -22px, 0) rotate(0);
    transform: translate3d(0, -22px, 0) rotate(0);
  }
  100% {
    -webkit-transform: translate3d(0, -22px, 0) rotate(135deg);
    transform: translate3d(0, -22px, 0) rotate(135deg);
  }
}

@-webkit-keyframes animate-line-1-rev {
  0% {
    -webkit-transform: translate3d(0, 14px, 0) rotate(45deg);
    transform: translate3d(0, 14px, 0) rotate(45deg);
  }
  50% {
    -webkit-transform: translate3d(0, 14px, 0) rotate(0);
    transform: translate3d(0, 14px, 0) rotate(0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) rotate(0deg);
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
}

@keyframes animate-line-1-rev {
  0% {
    -webkit-transform: translate3d(0, 14px, 0) rotate(45deg);
    transform: translate3d(0, 14px, 0) rotate(45deg);
  }
  50% {
    -webkit-transform: translate3d(0, 14px, 0) rotate(0);
    transform: translate3d(0, 14px, 0) rotate(0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) rotate(0deg);
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
}

@-webkit-keyframes animate-line-2-rev {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes animate-line-2-rev {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes animate-line-3-rev {
  0% {
    -webkit-transform: translate3d(0, -22px, 0) rotate(135deg);
    transform: translate3d(0, -22px, 0) rotate(135deg);
  }
  50% {
    -webkit-transform: translate3d(0, -22px, 0) rotate(0);
    transform: translate3d(0, -22px, 0) rotate(0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) rotate(0deg);
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
}

@keyframes animate-line-3-rev {
  0% {
    -webkit-transform: translate3d(0, -22px, 0) rotate(135deg);
    transform: translate3d(0, -22px, 0) rotate(135deg);
  }
  50% {
    -webkit-transform: translate3d(0, -22px, 0) rotate(0);
    transform: translate3d(0, -22px, 0) rotate(0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0) rotate(0deg);
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
}